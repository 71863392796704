<template>
  <div class="levelOneDiv1" style="background-color: #fff;">
    <div class="small_title">
      <templateTitle :title="title" :isBack="true" @handleBack="btnClose"></templateTitle>
      <el-button v-if="type == 'view'" size="mini" type="primary" plain icon="el-icon-edit" @click="editCols"
        >编辑</el-button
      >
    </div>

    <div v-if="type == 'add'">
      <el-form
        :inline="true"
        :model="searchObj"
        :rules="rules"
        ref="form"
        style="align-items: center;margin-bottom: 4px;"
        class="user-search flex-between"
        @submit.native.prevent
        label-position="top"
      >
        <div>
          <el-form-item class="budgetYear" label="新增年度" prop="year">
            <budgetYear ref="budgetYear" @refreshYear="refreshYear" />
          </el-form-item>
        </div>
      </el-form>
    </div>

    <div v-if="type == 'add'" class="small_title">
      <templateDivider title="典型抽查情况"></templateDivider>
      <el-button size="mini" type="primary" plain icon="el-icon-plus" @click="addCols">增加</el-button>
    </div>

    <el-row>
      <!-- 纵向表头设计 -->
      <el-col :span="5" style="background-color: #F9FAFE;">
        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTitletStyle">操作</div>
        </el-row>
        <el-row>
          <div class="leftTitletStyle">行政区域</div>
        </el-row>

        <!-- 项目基本情况 -->
        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">
              项目基本情况
            </div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <div class="leftTitletStyle">计划项目（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">涉及移民乡（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">涉及移民村（个）</div>
            </el-row>
            <el-row>
              <div class="leftTitletStyle">涉及移民组（个）</div>
            </el-row>
          </el-col>
        </el-row>

        <!-- 前期工作 -->
        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">前期工作</div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">确定方式</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">规划内项目（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">移民自选项目（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">移民参与项目（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">公示项目（个）</div>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <!-- 项目实施 -->
        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">项目实施</div>
          </el-col>

          <el-col :span="20">
            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">工程量</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">未完成项目（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">完成项目（个）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">完成率（%）</div>
                </el-row>
              </el-col>
            </el-row>

            <el-row>
              <el-col :span="8" class="title_view4">
                <div class="leftTitletStyle">投资</div>
              </el-col>
              <el-col :span="16">
                <el-row>
                  <div class="leftTitletStyle">计划（万元）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">完成（万元）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">完成率（%）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">拨付（万元）</div>
                </el-row>
                <el-row>
                  <div class="leftTitletStyle">拨付率（%）</div>
                </el-row>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <!-- 项目管理 -->
        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">项目管理</div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">应招投标项目（个）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">实际招投标项目（个）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">应建设监理项目（个）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">实际建设监理项目（个）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">移民自建项目（个）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">计划变更项目（个）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">其中未批准计划变更项目（个）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">移民参与监督项目（个）</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <!-- 项目验收 -->
        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">项目验收</div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">完成项目验收的（个）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">正在实施项目验收的（个）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">档案资料齐全（个）</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <!-- 受益人口 -->
        <el-row>
          <el-col :span="4" class="title_view2">
            <div class="firstTitletStyle">受益人口</div>
          </el-col>
          <el-col :span="20">
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">合计（人或人次）</div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <div class="leftTitletStyle">其中:移民（人或人次）</div>
              </el-col>
            </el-row>
          </el-col>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">查阅相关资料和信息（条）</div>
        </el-row>

        <el-row>
          <div class="leftTitletStyle">走访或与移民群众座谈（人/次）</div>
        </el-row>
      </el-col>
      <el-col :span="2" style="background-color: #F9FAFE;">
        <el-row>
          <div class="leftTotalStyle">合计</div>
        </el-row>

        <el-row v-if="type == 'add' || type == 'edit'">
          <div class="leftTotalStyle"></div>
        </el-row>

        <!-- 项目基本情况 -->
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('plannedProjects') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('affectedTownships') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('affectedVillages') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('affectedGroups') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- 前期工作 -->
        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('plannedProjectsInPlan') }}</div>
          </div>
        </el-row>

        <el-row>
          <div class="leftTotalStyle">
            <div>{{ calculateTheTotal('selfSelectedProjects') }}</div>
          </div>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('participatedProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('publicizedProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- 项目实施 -->
        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('incompleteProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('completedProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('completionRate') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('investmentPlan') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('investmentCompleted') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('investmentCompletionRate') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('investmentAllocation') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('investmentAllocationRate') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- 项目管理 -->
        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('tenderProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('actualTenderProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('supervisionProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('actualSupervisionProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('immigrationSelfBuiltProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('plannedChangeProjects') }}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('unapprovedChangeProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('participationSupervisionProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- 项目验收 -->
        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('completedAcceptanceProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('ongoingAcceptanceProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('completeDocumentationProjects') }}</div>
            </div>
          </el-col>
        </el-row>

        <!-- 受益人口-->
        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('totalBeneficiaries') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('immigrationBeneficiaries') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('informationAccessCount') }}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <div class="leftTotalStyle">
              <div>{{ calculateTheTotal('visitsToImmigrantMeetings') }}</div>
            </div>
          </el-col>
        </el-row>
      </el-col>

      <!-- 2.纵向数据展示 -->
      <el-col :span="17">
        <div class="main_box">
          <!-- -纵列数据遍历 -->
          <div :style="boxWidth" class="clearFloat">
            <div>
              <el-row class="flex">
                <el-row style="width: 150px;" v-for="(col, index) in listData" :key="col.id">
                  <el-col :span="24">
                    <el-row v-if="type == 'add'">
                      <div class="leftTitletStyle">
                        <el-link type="danger" @click="removeCol(index)">删除</el-link>
                      </div>
                    </el-row>

                    <el-row v-if="type == 'edit'">
                      <div class="leftTitletStyle"></div>
                    </el-row>

                    <el-col>
                      <template v-for="key1 in Object.keys(col)">
                        <el-row v-if="key1 == 'countyId'">
                          <div class="contentStyle" v-if="type == 'add' || type == 'edit'">
                            <el-cascader  :append-to-body="false"  
                              v-model="col[key1]"
                              size="mini"
                              :options="levalRegion"
                              :props="treeProps"
                              @change="handleChange($event, index)"
                              :show-all-levels="false"
                              placeholder="请选择"
                            >
                            </el-cascader>
                          </div>
                          <div class="contentStyle" v-else>
                            {{ col['county'] }}
                          </div>
                        </el-row>

                        <!-- 项目基本情况 -->
                        <el-row v-if="key1 == 'plannedProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'affectedTownships'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'affectedVillages'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'affectedGroups'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 前期工作 -->
                        <el-row v-if="key1 == 'plannedProjectsInPlan'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'selfSelectedProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'participatedProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'publicizedProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 项目实施 -->
                        <el-row v-if="key1 == 'incompleteProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'completedProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'completionRate'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{
                                +col['completedProjects'] + +col['incompleteProjects'] != 0
                                  ? Number.isNaN(
                                      +col['completedProjects'] /
                                        (+col['completedProjects'] + +col['incompleteProjects'])
                                    )
                                    ? 0
                                    : Number.isInteger(
                                        (+col['completedProjects'] /
                                          (+col['completedProjects'] + +col['incompleteProjects'])) *
                                          100
                                      )
                                    ? (+col['completedProjects'] /
                                        (+col['completedProjects'] + +col['incompleteProjects'])) *
                                      100
                                    : (
                                        (+col['completedProjects'] /
                                          (+col['completedProjects'] + +col['incompleteProjects'])) *
                                        100
                                      )?.toFixed(2)
                                  : 0
                              }}%
                            </div>

                            <!-- <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div> -->
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'investmentPlan'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'investmentCompleted'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'investmentCompletionRate'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{
                                +col['investmentPlan'] != 0
                                  ? !Number.isNaN(+col['investmentCompleted'] / +col['investmentPlan'])
                                    ? Number.isInteger((+col['investmentCompleted'] / +col['investmentPlan']) * 100)
                                      ? (+col['investmentCompleted'] / +col['investmentPlan']) * 100
                                      : ((+col['investmentCompleted'] / +col['investmentPlan']) * 100)?.toFixed(2)
                                    : 0
                                  : 0
                              }}%
                            </div>

                            <!-- <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div> -->
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'investmentAllocation'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                                oninput="
                                this.value = this.value.replace(/\D*(\d*)(\.?)(\d{0,3})\d*/,'$1$2$3') // 只能输入数字、小数点限制3位
                              .replace(/^0+(\d)/, '$1') // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
                              .replace(/^\./, '0.')  // 如果输入的第一位为小数点，则替换成 0. 实现自动补全
                              .match(/^\d*(\.?\d{0,3})/g)[0] || '' // 数字开头、小数点3位
                            "
                              min="0"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'investmentAllocationRate'">
                          <div class="contentStyle">
                            <div class="box_view">
                              {{
                                +col['investmentPlan'] != 0
                                  ? Number.isNaN(+col['investmentAllocation'] / +col['investmentPlan'])
                                    ? 0
                                    : Number.isInteger((+col['investmentAllocation'] / +col['investmentPlan']) * 100)
                                    ? (+col['investmentAllocation'] / +col['investmentPlan']) * 100
                                    : ((+col['investmentAllocation'] / +col['investmentPlan']) * 100)?.toFixed(2)
                                  : 0
                              }}%
                            </div>

                            <!-- <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div> -->
                          </div>
                        </el-row>

                        <!-- 项目管理 -->
                        <el-row v-if="key1 == 'tenderProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'actualTenderProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'supervisionProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'actualSupervisionProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'immigrationSelfBuiltProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'plannedChangeProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'unapprovedChangeProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'participationSupervisionProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 项目验收 -->
                        <el-row v-if="key1 == 'completedAcceptanceProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'ongoingAcceptanceProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'completeDocumentationProjects'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <!-- 收益人口 -->
                        <el-row v-if="key1 == 'totalBeneficiaries'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'immigrationBeneficiaries'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'informationAccessCount'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>

                        <el-row v-if="key1 == 'visitsToImmigrantMeetings'">
                          <div class="contentStyle">
                            <el-input
                              v-if="type == 'add' || type == 'edit'"
                              size="mini"
                              type="number"
                              min="0"
                              oninput="value=value.replace(/[^\d]/g,'')"
                              v-model="col[key1]"
                              placeholder="请输入"
                            >
                            </el-input>
                            <div v-else class="box_view">{{ col[key1] == null ? '--' : col[key1] }}</div>
                          </div>
                        </el-row>
                      </template>
                    </el-col>
                  </el-col>
                </el-row>
              </el-row>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="import_opration" v-if="type == 'add' || type == 'edit'">
      <el-button size="small" plain @click="btnClose()">取消</el-button>
      <el-button type="primary" size="small" @click="submitForm()">提交</el-button>
    </div>
  </div>
</template>

<script>
import templateTitle from '@/components/templateView/templateTitle.vue'
import templateDivider from '@/components/templateView/templateDivider.vue'
import budgetYear from '@/components/templateView/budgetYear.vue'

import { _getLevelRegion } from '@/api/keepHome'

import {
  _PorjectTypicalSpotChecksAdd,
  _PorjectTypicalSpotChecksEdit,
  _PorjectTypicalSpotChecksInfo
} from '@/api/modular/postImmigrationService/policy/typicalSpotCheck.js'

export default {
  data() {
    return {
      type: '',
      row: {},
      title: '',
      input: '',
      listData: [],
      value: '2021年度',
      searchObj: {
        year: ''
      },
      rules: {
        year: [{ required: true, message: '请选择年度', trigger: 'blur' }]
      },
      levalRegion: [], //成都市
      treeProps: { label: 'name', value: 'id', children: 'childRegions', pid: 'pids', checkStrictly: true }
    }
  },
  // 注册组件
  components: {
    templateTitle,
    templateDivider,
    budgetYear
  },
  computed: {
    //
    boxWidth() {
      let num = 150 * this.listData.length
      return `width:${num}px`
    }
  },
  created() {
    this.getLevelRegion()
  },
  mounted() {},

  methods: {
    handleChange(value, index) {
      let obj = this.getPids(value)
      this.listData[index].countyId = obj.id
      this.listData[index].county = obj.name
      this.listData[index].countyPids = obj.pids
    },
    // 获取选中节点的pids值
    getPids(selectedOptions) {
      const selectedNode = this.findNodeById(this.levalRegion, selectedOptions[selectedOptions.length - 1])
      return selectedNode ? selectedNode : []
    },

    // 在数据源中查找指定ID的节点
    findNodeById(nodes, id) {
      for (const node in nodes) {
        if (nodes[node].id === id) {
          return nodes[node]
        }
        if (nodes[node].childRegions && nodes[node].childRegions.length > 0) {
          const foundNode = this.findNodeById(nodes[node].childRegions, id)
          if (foundNode) {
            return foundNode
          }
        }
      }
      return null
    },
    getLevelRegion() {
      let params = {
        startLevel: 2,
        endLevel: 3,
        inclusionLevel: true
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.levalRegion = this.formatTreeData(res.data)
        }
      })
    },
    formatTreeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childRegions.length < 1) {
          data[i].childRegions = undefined
        } else {
          this.formatTreeData(data[i].childRegions)
        }
      }
      return data
    },
    refreshYear(year) {
      this.searchObj.year = year
    },
    calculateTheTotal(name) {
      let sum = 0
      if (name == 'completionRate') {
        // 完成率
        let sum1 = this.calculateTheTotal('completedProjects')
        let sum2 = this.calculateTheTotal('incompleteProjects')

        let rate =
          sum1 + sum2 != 0
            ? Number.isNaN(sum1 / (sum1 + sum2))
              ? 0
              : Number.isInteger((sum1 / (sum1 + sum2)) * 100)
              ? (sum1 / (sum1 + sum2)) * 100
              : ((sum1 / (sum1 + sum2)) * 100)?.toFixed(2)
            : 0
        return rate
      } else if (name == 'investmentCompletionRate') {
        // 完成率
        let sum1 = this.calculateTheTotal('investmentCompleted')
        let sum2 = this.calculateTheTotal('investmentPlan')

        let rate =
          sum2 != 0
            ? Number.isNaN(sum1 / sum2)
              ? 0
              : Number.isInteger((sum1 / sum2) * 100)
              ? (sum1 / sum2) * 100
              : ((sum1 / sum2) * 100)?.toFixed(2)
            : 0
        return rate
      } else if (name == 'investmentAllocationRate') {
        // 拨付率
        let sum1 = this.calculateTheTotal('investmentAllocation')
        let sum2 = this.calculateTheTotal('investmentPlan')

        let rate =
          sum2 != 0
            ? Number.isNaN(sum1 / sum2)
              ? 0
              : Number.isInteger((sum1 / sum2) * 100)
              ? (sum1 / sum2) * 100
              : ((sum1 / sum2) * 100)?.toFixed(2)
            : 0
        return rate
      } else {
        for (let i = 0; i < this.listData.length; i++) {
          let ele = this.listData[i]
          sum += Number(ele[name])
        }
      }

      return sum
    },
    add(type, row) {
      this.type = type
      this.row = row
      this.title = '新增'
    },
    view(type, row) {
      this.type = type
      this.row = row
      this.title = type == 'view' ? '详情' : '查看统计表'
      this.getBasicInfo()
    },

    editCols() {
      this.type = 'edit'
      this.title = '编辑'
    },

    btnClose() {
      this.listData = []
      this.$emit('handleBack')
    },
    addCols() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          let obj = {
            year: this.searchObj.year,
            countyId: 1,
            county: '',
            countyPids: '',

            plannedProjects: null,
            plannedInvestment: null,
            affectedTownships: null,
            affectedVillages: null,
            affectedGroups: null,

            plannedProjectsInPlan: null,
            selfSelectedProjects: null,
            participatedProjects: null,
            publicizedProjects: null,

            incompleteProjects: null,
            completedProjects: null,
            completionRate: null,

            investmentPlan: null,
            investmentCompleted: null,
            investmentCompletionRate: null,
            investmentAllocation: null,
            investmentAllocationRate: null,

            tenderProjects: null,
            actualTenderProjects: null,
            supervisionProjects: null,
            actualSupervisionProjects: null,
            immigrationSelfBuiltProjects: null,
            plannedChangeProjects: null,
            unapprovedChangeProjects: null,
            participationSupervisionProjects: null,

            completedAcceptanceProjects: null,
            ongoingAcceptanceProjects: null,
            completeDocumentationProjects: null,

            totalBeneficiaries: null,
            immigrationBeneficiaries: null,
            informationAccessCount: null,
            visitsToImmigrantMeetings: null
          }

          if (this.listData.length) {
            let flag = this.listData.every(ele => ele.countyId)
            if (flag) {
              this.listData.splice(this.listData.length, 0, obj)
            } else {
              this.$message.error('请选择县（市区）')
            }
          } else {
            this.listData.splice(this.listData.length, 0, obj)
          }
        }
      })
    },
    removeCol(index) {
      this.$confirm('确定要删除吗?', '信息', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.listData.splice(index, 1)
          this.$message.success('删除成功')
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    submitForm() {
      console.log(this.listData, '===submitForm===')
      let listData = this.listData.map(ele => {
        return {
          ...ele,
          completionRate:
            +ele.completedProjects + +ele.incompleteProjects != 0
              ? Number.isNaN(+ele.completedProjects / (+ele.completedProjects + +ele.incompleteProjects))
                ? 0
                : Number.isInteger((+ele.completedProjects / (+ele.completedProjects + +ele.incompleteProjects)) * 100)
                ? (+ele.completedProjects / (+ele.completedProjects + +ele.incompleteProjects)) * 100
                : ((+ele.completedProjects / (+ele.completedProjects + +ele.incompleteProjects)) * 100)?.toFixed(2)
              : 0,
          investmentCompletionRate:
            +ele.investmentPlan != 0
              ? Number.isNaN(+ele.investmentCompleted / +ele.investmentPlan)
                ? 0
                : Number.isInteger((+ele.investmentCompleted / +ele.investmentPlan) * 100)
                ? (+ele.investmentCompleted / +ele.investmentPlan) * 100
                : ((+ele.investmentCompleted / +ele.investmentPlan) * 100)?.toFixed(2)
              : 0,
          investmentAllocationRate:
            +ele.investmentPlan != 0
              ? Number.isNaN(+ele.investmentAllocation / +ele.investmentPlan)
                ? 0
                : Number.isInteger((+ele.investmentAllocation / +ele.investmentPlan) * 100)
                ? (+ele.investmentAllocation / +ele.investmentPlan) * 100
                : ((+ele.investmentAllocation / +ele.investmentPlan) * 100)?.toFixed(2)
              : 0
        }
      })

      let flag = listData.every(ele => ele.countyId)

      switch (this.type) {
        case 'add':
          if (flag) {
            _PorjectTypicalSpotChecksAdd(listData).then(res => {
              if (res.code == 200) {
                this.$message.success('添加成功')
                this.btnClose()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }
          break
        case 'edit':
          if (flag) {
            _PorjectTypicalSpotChecksEdit(listData[0]).then(res => {
              if (res.code == 200) {
                this.type = 'view'
                this.$message.success('修改成功')
                this.getBasicInfo()
              } else {
                this.$message.error(res.message)
              }
            })
          } else {
            this.$message.error('请选择县（市区）')
          }
          break
      }
    },
    getBasicInfo() {
      let params = {
        id: this.row.id
      }

      _PorjectTypicalSpotChecksInfo(params).then(res => {
        if (res.code == 200) {
          let arr = [res.data]
          this.listData = this.formateResData(arr)
        }
      })
    },
    formateResData(arr) {
      return arr.map(ele => {
        return {
          year: ele.year, //年份
          countyId: ele.countyId, //县（市区）
          county: ele.county, //县（市区）

          plannedProjects: ele.plannedProjects,
          affectedTownships: ele.affectedTownships,
          affectedVillages: ele.affectedVillages,
          affectedGroups: ele.affectedGroups,

          plannedProjectsInPlan: ele.plannedProjectsInPlan,
          selfSelectedProjects: ele.selfSelectedProjects,
          participatedProjects: ele.participatedProjects,
          publicizedProjects: ele.publicizedProjects,

          incompleteProjects: ele.incompleteProjects,
          completedProjects: ele.completedProjects,
          completionRate: ele.completionRate,

          investmentPlan: ele.investmentPlan,
          investmentCompleted: ele.investmentCompleted,
          investmentCompletionRate: ele.investmentCompletionRate,
          investmentAllocation: ele.investmentAllocation,
          investmentAllocationRate: ele.investmentAllocationRate,

          tenderProjects: ele.tenderProjects,
          actualTenderProjects: ele.actualTenderProjects,
          supervisionProjects: ele.supervisionProjects,
          actualSupervisionProjects: ele.actualSupervisionProjects,
          immigrationSelfBuiltProjects: ele.immigrationSelfBuiltProjects,
          plannedChangeProjects: ele.plannedChangeProjects,
          unapprovedChangeProjects: ele.unapprovedChangeProjects,
          participationSupervisionProjects: ele.participationSupervisionProjects,

          completedAcceptanceProjects: ele.completedAcceptanceProjects,
          ongoingAcceptanceProjects: ele.ongoingAcceptanceProjects,
          completeDocumentationProjects: ele.completeDocumentationProjects,

          totalBeneficiaries: ele.totalBeneficiaries,
          immigrationBeneficiaries: ele.immigrationBeneficiaries,
          informationAccessCount: ele.informationAccessCount,
          visitsToImmigrantMeetings: ele.visitsToImmigrantMeetings,

          countyPids: ele.countyPids,
          id: ele.id
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/common/scss/postImmigrationService.scss';
@import '@/common/scss/formview.scss';
</style>
